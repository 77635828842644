<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-btn small :to="{ name: 'admin-banners' }" elevation="0"
            >GERİ DÖN</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              <h2>Banner Güncelle</h2>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-form ref="form" v-model="formIsValid">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Başlık"
                      v-model="form.title"
                      required
                      :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Alt Başlık"
                      v-model="form.preTitle"
                      required
                      :rules="requiredRule"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Link Adı"
                      v-model="form.linkTitle"
                      required
                      :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Link"
                      v-model="form.link"
                      required
                      :rules="requiredRule"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-img
                      :src="imgDownloadUrl"
                      width="100%"
                      contain
                      aspect-ratio="2.67"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-file-input
                      v-model="form.img"
                      label="Banner Resmi"
                      accept=".png, .jpeg, .jpg, .gif, .svg"
                      truncate-length="50"
                      :rules="imgRules"
                      :loading="imgLoading"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-menu
                      v-model="datePicker"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      left
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.dateCreated"
                          label="Tarih"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.dateCreated"
                        @input="datePicker = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ckeditor
                      :editor="editor"
                      :config="editorConfig"
                      v-model="form.text"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="pa-4">
              <v-row>
                <v-col cols="12" md="6">
                  <v-btn
                    large
                    block
                    elevation="0"
                    color="secondary"
                    @click="submitForm()"
                    class="mr-4"
                    >KAYDET</v-btn
                  >
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn
                    block
                    large
                    elevation="0"
                    color="secondary"
                    @click="del()"
                    >Sil</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <confirm ref="confirm" />
  </div>
</template>
<script>
import Vue from "vue";
import firebase from "@/plugins/firebase";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Confirm from "@/components/Confirm.vue";

export default Vue.extend({
  metaInfo: {
    title: "Banner Güncelle"
  },

  components: {
    ckeditor: CKEditor.component,
    Confirm
  },

  data: () => {
    return {
      formIsValid: false,

      form: {
        id: "",
        title: "",
        preTitle: "",
        img: null,
        text: "",
        link: "",
        linkTitle: "Detaylar",
        dateCreated: new Date().toISOString().substr(0, 10)
      },

      datePicker: false,

      requiredRule: [v => !!v || "Zorunlu alan"],

      imgLoading: false,

      imgRules: [
        v => !v || v.size < 2097152 || "Dosya boyutu 2 MB'den küçük olmalıdır.",
        v =>
          !v ||
          !/[^A-Za-z0-9-_.]+/g.test(v.name) ||
          "Dosya adı Türkçe, özel karakter ve boşluk içermemelidir. Ör: dosya-adi.png"
      ],

      imgDownloadUrl: "",

      editor: ClassicEditor,

      editorConfig: {
        language: "tr",
        toolbar: [
          "undo",
          "redo",
          "|",
          "heading",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "highlight",
          "subscript",
          "superscript",
          "specialCharacters",
          "|",
          "alignment",
          "numberedList",
          "bulletedList",
          "outdent",
          "indent",
          "blockQuote",
          "|",
          "removeFormat",
          "link",
          "insertTable",
          "mediaEmbed",
          "imageInsert",
          "htmlEmbed",
          "code"
        ],
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
            "linkImage"
          ]
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableCellProperties",
            "tableProperties"
          ]
        },
        mediaEmbed: {
          previewsInData: true
        }
      }
    };
  },

  methods: {
    async uploadimg() {
      this.imgLoading = true;

      try {
        const img = this.form.img;

        if (!img) {
          return;
        }

        // const fileExt = img.name.substring(
        //   img.name.lastIndexOf("."),
        //   img.name.length
        // );
        const fileName = img.name;

        const uploadTask = await firebase
          .storage()
          .ref()
          .child(`/banners/${fileName}`)
          .put(img);

        this.imgDownloadUrl = await uploadTask.ref.getDownloadURL();
      } catch (err) {
        this.$notify({
          title: "Hata!",
          text: `Banner resmi yüklenemedi. Lütfen daha sonra tekrar deneyiniz.`,
          type: "error"
        });

        console.error(`Banner resmi yüklenemedi. ${err.code} ${err.message}`);
      } finally {
        this.imgLoading = false;
      }
    },

    async submitForm() {
      const form = this.$refs.form;

      if (!form.validate()) {
        return;
      }

      try {
        let banner = {};

        if (this.form.img) {
          // Ön izleme resmini yükle
          await this.uploadimg();

          banner = {
            title: this.form.title,
            preTitle: this.form.preTitle,
            text: this.form.text,
            dateCreated: new Date(this.form.dateCreated),
            dateUpdated: firebase.firestore.FieldValue.serverTimestamp(),
            disabled: this.form.disabled,
            img: this.imgDownloadUrl,
            linkTitle: this.form.linkTitle,
            link: this.form.link
          };
        } else {
          banner = {
            title: this.form.title,
            preTitle: this.form.preTitle,
            text: this.form.text,
            dateCreated: new Date(this.form.dateCreated),
            dateUpdated: firebase.firestore.FieldValue.serverTimestamp(),
            disabled: this.form.disabled,
            linkTitle: this.form.linkTitle,
            link: this.form.link
          };
        }

        this.$notify({
          type: "warning",
          title: "Lütfen bekleyin!",
          text: "Banner güncelleniyor..."
        });

        await firebase
          .firestore()
          .doc(`/apps/kahev-org/banners/${this.form.id}`)
          .update(banner);

        this.$notify({
          type: "success",
          title: "İşlem Tamam!",
          text: "Banner başarıyla güncellendi."
        });
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Hata!",
          text: "Banner oluşturulamadı."
        });

        console.error(err);
      }
    },

    async getBanner() {
      const id = this.$route.params.id;

      try {
        const docRef = await firebase
          .firestore()
          .doc(`/apps/kahev-org/banners/${id}`)
          .get();

        if (docRef.exists) {
          this.form.id = docRef.id;
          this.form.title = docRef.data()?.title;
          this.form.preTitle = docRef.data()?.preTitle;
          this.form.text = docRef.data()?.text;
          this.imgDownloadUrl = docRef.data()?.img;
          this.form.dateCreated = docRef
            .data()
            ?.dateCreated.toDate()
            .toISOString()
            .substr(0, 10);
          this.form.disabled = docRef.data()?.disabled;
          this.form.linkTitle = docRef.data()?.linkTitle;
          this.form.link = docRef.data()?.link;
        } else {
          console.error("Bu Id ile herhangi bir bannera erişilemedi!");
        }
      } catch (err) {
        console.error(`${err.code} ${err.message}`);
      }
    },

    async del() {
      const confirm = this.$refs.confirm;

      const confirmation = await confirm.open(
        "Emin misiniz?",
        "Bu bannerı silmek istediğinizden emin misiniz?",
        {
          color: "red"
        }
      );

      if (!confirmation) return;

      try {
        // Bannerı sil
        await firebase
          .firestore()
          .doc(`/apps/kahev-org/banners/${this.form.id}`)
          .delete();

        // Banner resmini sil
        const imgRef = firebase.storage().refFromURL(this.form.img);
        await imgRef.delete();

        this.$router.push({ name: "admin-banners" });
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Hata!",
          text: "Banner silinemedi."
        });

        console.error(err);
      }
    }
  },

  mounted() {
    this.getBanner();
  }
});
</script>
<style lang="scss" scoped></style>
